import React from 'react';

import "./App.css";

//multilang
import "./i18n/i18n";

//redux persist
import { Provider } from "react-redux";
//import { persistStore } from "redux-persist";
//import { PersistGate } from "redux-persist/integration/react";
import store from "./store/index";
import { BrowserRouter as Router } from "react-router-dom";
import { ApiEndPoint } from "msbookingapiclient";

import Navigation from "./Components/Navigation";

function App() {
  sessionStorage.setItem("_APIDOMAIN", "https://api.macrodatum.com/api");
  ApiEndPoint.domain = sessionStorage._APIDOMAIN;
  //let persistor = persistStore(store);

  document.body.style.backgroundColor = "#D3D3D3";

  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Navigation></Navigation>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
