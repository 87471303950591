import React, { useState } from "react";
import Header from "../Header";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { createCompany } from "msbookingapiclient";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function RegCompany(props) {
  let navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const [businessHotel, setBusinessHotel] = useState({
    TypeBussines: "Alojamiento",
    isPrincipal: false,
    name: "",
    Selected: false,
  });
  // eslint-disable-next-line no-unused-vars
  const [businessRestaurant, setBusinessRestaurant] = useState({
    TypeBussines: "Restaurante",
    isPrincipal: false,
    name: "",
    Selected: false,
  });
  // eslint-disable-next-line no-unused-vars
  const [businessTour, setBusinessTour] = useState({
    TypeBussines: "Toures",
    isPrincipal: false,
    name: "",
    Selected: false,
  });
  // eslint-disable-next-line no-unused-vars
  const [businessDiving, setBusinessDiving] = useState({
    TypeBussines: "Buceo",
    isPrincipal: false,
    name: "",
    Selected: false,
  });

  const onChangeListener = (ev) => {
    switch (ev.target.name) {
      case "hotelName":
        businessHotel.name = ev.target.value;
        break;
      case "restaurantName":
        businessRestaurant.name = ev.target.value;
        break;
      case "divingName":
        businessDiving.name = ev.target.value;
        break;
      case "tourName":
        businessTour.name = ev.target.value;
        break;
      default:
        break;
    }
  };

  const onSwitchListener = (ev) => {
    switch (ev.target.name) {
      case "hotelChk":
        businessHotel.Selected = ev.target.checked;
        break;
      case "restaurantChk":
        businessRestaurant.Selected = ev.target.checked;
        break;
      case "divingChk":
        businessDiving.Selected = ev.target.checked;
        break;
      case "tourChk":
        businessTour.Selected = ev.target.checked;
        break;
      default:
        break;
    }
  };

  const collectBusiness = () => {
    let businessArray = [];
    if (businessHotel.Selected) {
      businessArray.push(businessHotel);
    }
    if (businessRestaurant.Selected) {
      businessArray.push(businessRestaurant);
    }
    if (businessDiving.Selected) {
      businessArray.push(businessDiving);
    }
    if (businessDiving.Selected) {
      businessArray.push(businessTour);
    }
    return businessArray;
  };

  return (
    <div>
      <Header
        userStatus={props.userStatus}
        title="List Company"
        subtitle="List Company"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body ">
                <div className="card-header">
                  <h3 className="card-title">Registrar Nueva Empresa</h3>
                </div>
                <div className="card-body">
                  <Formik
                    initialValues={{
                      name: "",
                      nit: "",
                      legalRepresentant: "",
                      LegalDocumentType: "",
                      legalDocumentNumber: "",
                      legalAddress: "",
                      legalPhone: "",
                      legalEmail: "",
                    }}
                    validate={(valores) => {
                      let errores = {};
                      if (!valores.name) {
                        errores.name = "Debes de completar este campo";
                      }
                      if (!valores.nit) {
                        errores.nit = "Debes de completar este campo";
                      }
                      if (!valores.legalRepresentant) {
                        errores.legalRepresentant =
                          "Debes de completar este campo";
                      }
                      if (!valores.legalEmail) {
                        errores.legalEmail = "Debes de completar este campo";
                      }
                      if (!valores.LegalDocumentType) {
                        errores.LegalDocumentType =
                          "Debes de selecionar tu tipo de documento";
                      }
                      if (!valores.legalPhone) {
                        errores.legalPhone = "Debes de completar este campo";
                      }
                      if (!valores.legalDocumentNumber) {
                        errores.legalDocumentNumber =
                          "Debes de completar este campo";
                      }
                      if (!valores.legalAddress) {
                        errores.legalAddress = "Debes de completar este campo";
                      }
                      return errores;
                    }}
                    //desde aqui en vio al api
                    onSubmit={(valores, { resetForm }) => {
                      Swal.fire({
                        title: "Desea crear esta empresa?",
                        showDenyButton: true,
                        confirmButtonText: "Si",
                        denyButtonText: `No`,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          const business = collectBusiness();
                          valores.legalPhone = "+" + valores.legalPhone;
                          createCompany(valores, business)
                            .then((result) => {
                              Swal.fire({
                                title: "Empresa creada correctamente.",
                                confirmButtonText: "OK",
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  navigate("/ListCompany");
                                }
                              });
                            })
                            .catch((error) => {
                              console.log(error);
                              Swal.fire("Error creado empresa");
                            });
                          //resetForm();
                        } else if (result.isDenied) {
                          return;
                        }
                      });
                    }}
                  >
                    {({ errors }) => (
                      <Form className="row g-3">
                        <div className="col-md-6">
                          <label className="form-label">Nombre Empresa</label>
                          <Field
                            type="text"
                            name="name"
                            id="name"
                            className="form-control"
                            placeholder="El nombre de la empresa"
                          />
                          <ErrorMessage
                            name="name"
                            component={() => (
                              <div className="text-danger">{errors.name}</div>
                            )}
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">Nit Empresa</label>
                          <Field
                            type="text"
                            name="nit"
                            id="nit"
                            className="form-control"
                            placeholder="El Nit de la empresa"
                          />
                          <ErrorMessage
                            name="nit"
                            component={() => (
                              <div className="text-danger">{errors.nit}</div>
                            )}
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">
                            Representante Legal
                          </label>
                          <Field
                            type="text"
                            name="legalRepresentant"
                            id="legalRepresentant"
                            className="form-control"
                            placeholder="El nombre del representante"
                          />
                          <ErrorMessage
                            name="legalRepresentant"
                            component={() => (
                              <div className="text-danger">
                                {errors.legalRepresentant}
                              </div>
                            )}
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">
                            Tipo de documento
                          </label>
                          <Field
                            name="LegalDocumentType"
                            as="select"
                            className="form-select"
                          >
                            <option disabled value="">
                              Seleciona tu tipo de documento
                            </option>
                            <option value="Cédula de ciudadanía">
                              Cédula de ciudadanía
                            </option>
                            <option value="Cédula de extranjería">
                              Cédula de extranjería
                            </option>
                            <option value="Pasaporte">Pasaporte</option>
                            <option value="NIT">NIT</option>
                          </Field>
                          <ErrorMessage
                            name="LegalDocumentType"
                            component={() => (
                              <div className="text-danger">
                                {errors.LegalDocumentType}
                              </div>
                            )}
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">
                            Identificacion Representante
                          </label>
                          <Field
                            type="text"
                            name="legalDocumentNumber"
                            id="legalDocumentNumber"
                            className="form-control"
                            placeholder="Numero de identificacion"
                          />
                          <ErrorMessage
                            name="legalDocumentNumber"
                            component={() => (
                              <div className="text-danger">
                                {errors.legalDocumentNumber}
                              </div>
                            )}
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">Dirección</label>
                          <Field
                            type="text"
                            name="legalAddress"
                            id="legalAddress"
                            className="form-control"
                            placeholder="El nombre de la empresa"
                          />
                          <ErrorMessage
                            name="legalAddress"
                            component={() => (
                              <div className="text-danger">
                                {errors.legalAddress}
                              </div>
                            )}
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">
                            Numero Telefonico Empresa
                          </label>
                          <Field
                            type="text"
                            name="legalPhone"
                            id="legalPhone"
                            className="form-control"
                            placeholder="El numero de la empresa"
                          />
                          <ErrorMessage
                            name="legalPhone"
                            component={() => (
                              <div className="text-danger">
                                {errors.legalPhone}
                              </div>
                            )}
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">
                            Correo Electronico Empresa
                          </label>
                          <Field
                            type="email"
                            name="legalEmail"
                            id="legalEmail"
                            className="form-control"
                            placeholder="Digita el correo electronico de la empresa"
                          />
                          <ErrorMessage
                            name="legalEmail"
                            component={() => (
                              <div className="text-danger">
                                {errors.legalEmail}
                              </div>
                            )}
                          />
                        </div>

                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary mx-2"
                          >
                            Registrar
                          </button>
                          <Link
                            type="submit"
                            className="btn btn-danger "
                            to="/ListCompany"
                          >
                            Cancelar
                          </Link>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body ">
                <div className="card-header">
                  <h3 className="card-title">Activar Negocio</h3>
                </div>
                <div className="card-body">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>
                          Tipo de Negocio
                        </th>
                        <th>Nombre del Negocio</th>
                        <th>Estado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Hotel</td>
                        <td>
                          <input
                            className="form-control"
                            name="hotelName"
                            type="text"
                            onChange={onChangeListener}
                          />
                        </td>
                        <td>
                          <label className="switch">
                            <input
                              type="checkbox"
                              name="hotelChk"
                              onChange={onSwitchListener}
                            />
                            <span className="slider round"></span>
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>Restaurante</td>
                        <td>
                          <input
                            className="form-control"
                            name="restaurantName"
                            type="text"
                            onChange={onChangeListener}
                          />
                        </td>

                        <td>
                          <label className="switch">
                            <input
                              type="checkbox"
                              name="restaurantChk"
                              onChange={onSwitchListener}
                            />
                            <span className="slider round"></span>
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>Buceo</td>
                        <td>
                          <input
                            className="form-control"
                            name="divingName"
                            type="text"
                            onChange={onChangeListener}
                          />
                        </td>
                        <td>
                          <label className="switch">
                            <input
                              type="checkbox"
                              name="divingChk"
                              onChange={onSwitchListener}
                            />
                            <span className="slider round"></span>
                          </label>
                        </td>
                      </tr>
                      <tr>
                        <td>Toures</td>
                        <td>
                          <input
                            className="form-control"
                            name="tourName"
                            type="text"
                            onChange={onChangeListener}
                          />
                        </td>
                        <td>
                          <label className="switch">
                            <input
                              type="checkbox"
                              name="tourChk"
                              onChange={onSwitchListener}
                            />
                            <span className="slider round"></span>
                          </label>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegCompany;
