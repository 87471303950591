import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Bar } from "react-chartjs-2";
// eslint-disable-next-line no-unused-vars
import Chart from "chart.js/auto";
import { Link } from "react-router-dom";
import { FcBusiness, FcBusinessContact } from "react-icons/fc";
import { Footer } from "../Footer";
import { getCompanies } from "msbookingapiclient/src/Company";

function ComponentsHome() {

  const [companies, setCompaniesArray] = useState([]);

  useEffect(() => {
    getCompanies()
      .then((result) => {
        setCompaniesArray(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
console.log(companies);

  const data1 = {
    labels: [
      "Lunes",
      "Martes",
      "Miercoles",
      "Jueves",
      "Viernes",
      "Sabado",
      "Domingo",
    ],
    datasets: [
      {
        label: "Empresas",
        backgroundColor: "blue",
        borderColor: "black",
        hoverBackgroundColor: "rgba(0,255,0,0.2)",
        hoverBorderColor: "yellow",
        data: [43, 34, 25, 30, 28, 40, 41],
      },
      {
        label: "Negocios",
        backgroundColor: "green",
        borderColor: "black",
        hoverBackgroundColor: "rgba(0,255,0,0.2)",
        hoverBorderColor: "yellow",
        data: [43, 34, 25, 30, 28, 40, 41],
      },
    ],
  };
  const data2 = {
    labels: [
      "Lunes",
      "Martes",
      "Miercoles",
      "Jueves",
      "Viernes",
      "Sabado",
      "Domingo",
    ],
    datasets: [
      {
        label: "Users",
        backgroundColor: "purple",
        borderColor: "black",
        hoverBackgroundColor: "rgba(0,255,0,0.2)",
        hoverBorderColor: "yellow",
        data: [23, 14, 5, 10, 18, 20, 21],
      },
    ],
  };
  const opciones = {
    maintainAspectRatio: false,
    responsive: true,
  };

  return (
    <>
      <Container style={{ marginLeft: "10%",marginTop: "5%" }}>
        <Row>
          <Col md={6} sm={12} pb={5}>
            <Card className="conteiner">
              <Card.Body>
                <Card.Title className="title">
                  Grafica Empresas Registradas
                </Card.Title>
                <Card.Text>
                  <Bar data={data1} options={opciones} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} sm={12}>
            <Card className="conteiner">
              <Card.Body>
                <Card.Title className="title">
                  Grafica Usuarios Registrados
                </Card.Title>
                <Card.Text>
                  <Bar data={data2} options={opciones} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <br></br>
      <br></br>
      <Container>
        <Row>
          <Col md={4} style={{ marginLeft: "10%" }}>
            <Card className="conteiner">
              <Card.Body>
                <Card.Title className="text-center">Listar De empresas</Card.Title>
                <Card.Text className="text-center">
                  <Link to="/ListCompany">
                    <span>
                      <FcBusiness style={{ fontSize: "10em" }} />
                    </span>
                  </Link>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} style={{ marginLeft: "20%" }}>
            <Card className="conteiner">
              <Card.Body>
                <Card.Title className="text-center">Listar De Usuarios</Card.Title>
                <Card.Text className="text-center">
                  <Link to="/ListUsers">
                    <span>
                      <FcBusinessContact style={{ fontSize: "10em" }} />
                    </span>
                  </Link>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer style={{ marginTop: "3.8%" }} />
    </>
  );
}

export default ComponentsHome;
