import React from "react";
import Header from "./Header";
import Navbar from "react-bootstrap/Navbar";
import ComponentsHome from "./ComponentsHome";
function Dashboard(props) {
  return (
    <div id="caja_menu" style={{ textAlign: "left" }}>
      <Navbar.Brand className="h3" href="Dashboard"></Navbar.Brand>
      <div className="Header">
        <Header userStatus={props.userStatus} />
        <ComponentsHome />
      </div>
    </div>
  );
}

export default Dashboard;
