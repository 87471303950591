import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import Header from "../Header";
import Swal from "sweetalert2";

import {
  addReservationPackage,
  getCompanies,
  getPackageReservation,
} from "msbookingapiclient";

function NewPaqBilling(props) {
  // eslint-disable-next-line no-unused-vars
  const [formularioEnviado, cambiarForularioEnviado] = useState(false);
  let navigate = useNavigate();
  const { id } = useParams();
  const [paquetes, setPaquetes] = useState([]);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    getCompanies()
      .then((result) => {
        setCompanies(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getPackageReservation()
      .then((result) => {
        setPaquetes(result);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Header
        userStatus={props.userStatus}
        title="List Business"
        subtitle="List Business"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body ">
                <div className="card-header">
                  <h3 className="card-title">
                    Asignar Nuevo Paquetes de Reservas
                  </h3>
                </div>
                <div className="card-body">
                  <Formik
                    initialValues={{
                      reservation_package: "",
                      company: "",
                    }}
                    //desde aqui envio al api
                    onSubmit={(valores) => {
                      Swal.fire({
                        title: "Desea crear este Paquete de Reserva?",
                        showDenyButton: true,
                        confirmButtonText: "Si",
                        denyButtonText: `No`,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          addReservationPackage(
                            valores.reservation_package,
                            valores.company
                          ).then((result) => {
                            Swal.fire({
                              title: "Paquete de reserva creado correctamente.",
                              confirmButtonText: "OK",
                            })
                              .then((result) => {
                                if (result.isConfirmed) {
                                  navigate(`/BillingCompany/${id}`);
                                }
                              })
                              .catch((error) => {
                                Swal.fire("Error creando Paquete de Reserva");
                              });
                          });
                        } else if (result.isDenied) {
                          return;
                        }
                      });
                    }}
                  >
                    {({ errors }) => (
                      <Form className="row g-3">
                        <div className="col-md-6">
                          <label className="form-label">
                            Paquete de reservas
                          </label>
                          <Field
                            name="reservation_package"
                            as="select"
                            className="form-select"
                          >
                            <option disabled value="">
                              Seleciona el paquete de reservas
                            </option>
                            {paquetes.map((paq) => (
                              <option key={paq.id} value={paq.id}>
                                {paq.attributes.name}
                              </option>
                            ))}
                          </Field>
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">Empresa</label>
                          <Field
                            name="company"
                            as="select"
                            placeholder="Selecciona"
                            className="form-select"
                          >
                            <option disabled value="">
                              Seleciona la empresa
                            </option>
                            {companies.map((company) => (
                              <option key={company.id} value={company.id}>
                                {company.attributes.name}
                              </option>
                            ))}
                          </Field>
                        </div>

                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary mx-2"
                          >
                            Asignar
                          </button>
                          {formularioEnviado && (
                            <p className="text-primary">
                              Usuario registrado exitosa mente
                            </p>
                          )}
                          <Link
                            type="submit"
                            className="btn btn-danger "
                            to={`/BillingCompany/${id}`}
                          >
                            Cancelar
                          </Link>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewPaqBilling;
