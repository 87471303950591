import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import PageNotFound from "./PageNotFound";

import Dashboard from "./Dashboard";
import RegCompany from "./Company/RegCompany";
import EditCompany from "./Company/EditCompany";
import ViewCompany from "./Company/ViewCompany";
import ListCompany from "./Company/ListCompany";
import ListUsers from "./Usuario/ListUsers";
import ViewUsers from "./Usuario/ViewUsers";
import EditUsers from "./Usuario/EditUsers";
import RegUsers from "./Usuario/RegUsers";
import BillingCompany from "./BillingCompany/BillingCompany";
import NewSubBilling from "./BillingCompany/NewSubBilling";
import NewPaqBilling from "./BillingCompany/NewPaqBilling";

import Login from "./Login";
import PrivateRoute from "./PrivateRoute";

function Navigation() {
  const [userStatus, setUserStatus] = useState(
    JSON.parse(sessionStorage.getItem("_msSession"))
  );
  
  return (
    <Routes>
      <Route index element={<Login userStatus={setUserStatus} />} />
      <Route
        exact
        path="/Dashboard"
        element={
          <PrivateRoute userStatus={userStatus}>
            <Dashboard userStatus={setUserStatus} />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/ListCompany"
        element={
          <PrivateRoute userStatus={userStatus}>
            <ListCompany userStatus={setUserStatus} />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/RegCompany"
        element={
          <PrivateRoute userStatus={userStatus}>
            <RegCompany userStatus={setUserStatus} />
          </PrivateRoute>
        }
      />
      <Route
        path="/EditCompany/:id"
        element={
          <PrivateRoute userStatus={userStatus}>
            <EditCompany userStatus={setUserStatus} />
          </PrivateRoute>
        }
      />
      <Route
        path="/ViewCompany/:id"
        element={
          <PrivateRoute userStatus={userStatus}>
            <ViewCompany userStatus={setUserStatus} />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/ListUsers"
        element={
          <PrivateRoute userStatus={userStatus}>
            <ListUsers userStatus={setUserStatus} />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/ViewUsers/:id"
        element={
          <PrivateRoute userStatus={userStatus}>
            <ViewUsers userStatus={setUserStatus} />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/EditUsers/:id"
        element={
          <PrivateRoute userStatus={userStatus}>
            <EditUsers userStatus={setUserStatus} />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/RegUsers"
        element={
          <PrivateRoute userStatus={userStatus}>
            <RegUsers userStatus={setUserStatus} />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/BillingCompany/:id"
        element={
          <PrivateRoute userStatus={userStatus}>
            <BillingCompany userStatus={setUserStatus} />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/NewSubBilling/:id"
        element={
          <PrivateRoute userStatus={userStatus}>
            <NewSubBilling userStatus={setUserStatus} />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/NewPaqBilling/:id"
        element={
          <PrivateRoute userStatus={userStatus}>
            <NewPaqBilling userStatus={setUserStatus} />
          </PrivateRoute>
        }
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default Navigation;
