import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import Header from "../Header";
import { getCompanyById } from "msbookingapiclient/src/Company";
import { Formik, Form, Field } from "formik";

function ViewCompany(props) {
  const { id } = useParams();

  const [companiesbyid, setCompaniesArray] = useState();
  const [bussines, setBussines] = useState();

  useEffect(() => {
    getCompanyById(id)
      .then((response) => {
        setCompaniesArray(response.data);
      })
      .catch((error) => {
        console.log("ErrorMessage");
      });
    getCompanyById(id)
      .then((result) => {
        setBussines(result.data.attributes.businesses.data);
      })
      .catch((error) => {
        console.log("ErrorMessage");
      });
  }, [id]);
  return (
    <div>
      <Header
        userStatus={props.userStatus}
        title="List Company"
        subtitle="List Company"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body ">
                <div className="card-header">
                  <h3 className="card-title">Detalles de la Empresa</h3>
                </div>
                <div className="card-body">
                  <Formik
                    initialValues={{
                      name: "",
                      nit: "",
                      legalRepresentant: "",
                      LegalDocumentType: "",
                      legalDocumentNumber: "",
                      legalAddress: "",
                      legalPhone: "",
                      legalEmail: "",
                      userEmpresa: "",
                    }}
                  >
                    {({ a }) => (
                      <Form className="row g-3">
                        {companiesbyid ? (
                          <>
                            <div className="col-md-6">
                              <label className="form-label">
                                Nombre Empresa
                              </label>
                              <Field
                                type="text"
                                name="name"
                                id="name"
                                className="form-control"
                                placeholder="El nombre de la empresa"
                                value={companiesbyid.attributes.name}
                                disabled
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">Nit Empresa</label>
                              <Field
                                type="text"
                                name="nit"
                                id="nit"
                                value={companiesbyid.attributes.nit}
                                className="form-control"
                                placeholder="El Nit de la empresa"
                                disabled
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">
                                Representante Legal
                              </label>
                              <Field
                                type="text"
                                name="legalRepresentant"
                                id="legalRepresentant"
                                value={
                                  companiesbyid.attributes.legalRepresentant
                                }
                                className="form-control"
                                placeholder="El nombre del representante"
                                disabled
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">
                                Tipo de documento
                              </label>
                              <Field
                                name="LegalDocumentType"
                                as="select"
                                className="form-select"
                                disabled
                              >
                                <option>
                                  {companiesbyid.attributes.LegalDocumentType}
                                </option>
                              </Field>
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">
                                Identificacion Representante
                              </label>
                              <Field
                                type="number"
                                name="legalDocumentNumber"
                                id="legalDocumentNumber"
                                value={
                                  companiesbyid.attributes.legalDocumentNumber
                                }
                                className="form-control"
                                placeholder="Numero de identificacion"
                                disabled
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">Dirección</label>
                              <Field
                                type="text"
                                name="legalAddress"
                                id="legalAddress"
                                value={companiesbyid.attributes.legalAddress}
                                className="form-control"
                                placeholder="El nombre de la empresa"
                                disabled
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">
                                Numero Telefonico Empresa
                              </label>
                              <Field
                                type="text"
                                name="legalPhone"
                                id="legalPhone"
                                value={companiesbyid.attributes.legalPhone}
                                className="form-control"
                                placeholder="El numero de la empresa"
                                disabled
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">
                                Correo Electronico Empresa
                              </label>
                              <Field
                                type="email"
                                name="legalEmail"
                                id="legalEmail"
                                value={companiesbyid.attributes.legalEmail}
                                disabled
                                className="form-control"
                                placeholder="Digita el correo electronico de la empresa"
                              />
                            </div>
                            <div className="col-12">
                              <Link
                                type="submit"
                                className="btn btn-primary "
                                to="/ListCompany"
                              >
                                Regresar
                              </Link>
                            </div>
                          </>
                        ) : (
                          "Loading......"
                        )}
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-body ">
                <div className="card-header">
                  <h3 className="card-title">Negocios</h3>
                </div>
                <div className="card-body">
                  <Table striped bordered hover>
                    <>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>
                            Tipo de Negocio <i className="fa fa-sort"></i>
                          </th>
                          <th>Nombre del Negocio</th>
                        </tr>
                      </thead>
                      {bussines && bussines.length > 0
                        ? bussines.map((comp) => (
                            <tbody>
                              <tr>
                                <td>{comp.id}</td>
                                <td>{comp.attributes.TypeBussines}</td>
                                <td>{comp.attributes.name}</td>
                              </tr>
                            </tbody>
                          ))
                        : "Loading......"}
                    </>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewCompany;
