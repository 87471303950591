import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Header from "../Header";
import Swal from "sweetalert2";

import { addSubscriptionToCompanyId, getCompanies } from "msbookingapiclient";

function NewSubBilling(props) {
  // eslint-disable-next-line no-unused-vars
  const [formularioEnviado, cambiarForularioEnviado] = useState(false);
  let navigate = useNavigate();
  const { id } = useParams();
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    getCompanies()
      .then((result) => {
        setCompanies(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Header
        userStatus={props.userStatus}
        title="List Business"
        subtitle="List Business"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body ">
                <div className="card-header">
                  <h3 className="card-title">Asignar Nueva subscricion</h3>
                </div>
                <div className="card-body">
                  <Formik
                    initialValues={{
                      year: "",
                      month: "",
                      amount: "",
                      status: "",
                      company: "",
                    }}
                    validate={(valores) => {
                      let errores = {};
                      if (!valores.year) {
                        errores.year = "Debes de completar este campo";
                      }
                      if (!valores.month) {
                        errores.month = "Debes de completar este campo";
                      }
                      if (!valores.amount) {
                        errores.amount = "Debes de completar este campo";
                      }
                      if (!valores.status) {
                        errores.status = "Debes de completar este campo";
                      }
                      if (!valores.company) {
                        errores.company = "Debes de completar este campo";
                      }
                      return errores;
                    }}
                    //desde aqui envio al api
                    onSubmit={(valores, { resetForm }) => {
                      Swal.fire({
                        title: "Desea asignar esta Subscricion?",
                        showDenyButton: true,
                        confirmButtonText: "Si",
                        denyButtonText: `No`,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          addSubscriptionToCompanyId(
                            valores.month,
                            valores.year,
                            valores.amount,
                            valores.company,
                            valores.status
                          ).then((result) => {
                            Swal.fire({
                              title: "Subscricion creada correctamente.",
                              confirmButtonText: "OK",
                            })
                              .then((result) => {
                                if (result.isConfirmed) {
                                  navigate(`/BillingCompany/${id}`);
                                }
                              })
                              .catch((error) => {
                                Swal.fire("Error creando Subscricion");
                              });
                          });
                        } else if (result.isDenied) {
                          return;
                        }
                      });
                    }}
                  >
                    {({ errors }) => (
                      <Form className="row g-3">
                        <div className="col-md-4">
                          <label className="form-label">Año</label>
                          <Field
                            type="number"
                            name="year"
                            id="year"
                            className="form-control"
                            placeholder="El año de la Subscricion"
                          />
                          <ErrorMessage
                            name="year"
                            component={() => (
                              <div className="text-danger">{errors.year}</div>
                            )}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="form-label">Mes</label>
                          <Field
                            name="month"
                            id="month"
                            as="select"
                            className="form-select"
                          >
                            <option value="">Seleciona el mes</option>
                            <option value="Enero">Enero</option>
                            <option value="Febrero">Febrero</option>
                            <option value="Marzo">Marzo</option>
                            <option value="Abril">Abril</option>
                            <option value="Mayo">Mayo</option>
                            <option value="Junio">Junio</option>
                            <option value="Julio">Julio</option>
                            <option value="Agosto">Agosto</option>
                            <option value="Septiembre">Septiembre</option>
                            <option value="Octubre">Octubre</option>
                            <option value="Noviembre">Noviembre</option>
                            <option value="Diciembre">Diciembre</option>
                          </Field>
                          <ErrorMessage
                            name="month"
                            component={() => (
                              <div className="text-danger">{errors.month}</div>
                            )}
                          />
                        </div>

                        <div className="col-md-4">
                          <label className="form-label">Precio</label>
                          <Field
                            type="number"
                            name="amount"
                            id="amount"
                            className="form-control"
                            placeholder="El precio de la Subscricion"
                          />
                          <ErrorMessage
                            name="amount"
                            component={() => (
                              <div className="text-danger">{errors.amount}</div>
                            )}
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">Estado</label>
                          <Field
                            name="status"
                            id="status"
                            as="select"
                            className="form-select"
                          >
                            <option value="">Seleciona el estado</option>
                            <option value="No pagado">No pagado</option>
                            <option value="Pagado">Pagado</option>
                          </Field>
                          <ErrorMessage
                            name="status"
                            component={() => (
                              <div className="text-danger">{errors.status}</div>
                            )}
                          />
                        </div>

                        <div className="col-md-6">
                          <label className="form-label">
                            Empresa para la Subscricion
                          </label>
                          <Field
                            name="company"
                            as="select"
                            className="form-select"
                          >
                            <option disabled value="">
                              Seleciona la empresa
                            </option>
                            {companies.map((company) => (
                              <option key={company.id} value={company.id}>
                                {company.attributes.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="company"
                            component={() => (
                              <div className="text-danger">
                                {errors.company}
                              </div>
                            )}
                          />
                        </div>

                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary mx-2"
                          >
                            Registrar
                          </button>
                          {formularioEnviado && (
                            <p className="text-primary">
                              Usuario registrado exitosa mente
                            </p>
                          )}
                          <Link
                            type="submit"
                            className="btn btn-danger "
                            to={`/BillingCompany/${id}`}
                          >
                            Cancelar
                          </Link>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewSubBilling;
