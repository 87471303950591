import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../Header";
import { getUserById, updateUser } from "msbookingapiclient";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function EditUsers(props) {
  let navigate = useNavigate();

  const { id } = useParams();

  const [Users, setCompany] = useState(null);

  useEffect(() => {
    getUserById(id)
      .then((response) => {
        setCompany(response);
      })
      .catch((error) => {
        console.log("ErrorMessage");
      });
  }, [id]);
  return (
    <div>
      <Header
        userStatus={props.userStatus}
        title="Edit Users"
        subtitle="Edit Users"
      />
      <div className="container-fluid">
        <div className="row">
          {Users ? (
            <>
              <div className="col">
                <div className="card">
                  <div className="card-body ">
                    <div className="card-header">
                      <h3 className="card-title">Editar Datos De un Usuario</h3>
                    </div>
                    <div className="card-body">
                      <Formik
                        initialValues={Users}
                        validate={(valores) => {
                          let errores = {};
                          if (!valores.username) {
                            errores.username = "Debes de completar este campo";
                          }
                          if (!valores.name) {
                            errores.name = "Debes de completar este campo";
                          }
                          if (!valores.phone) {
                            errores.phone = "Debes de completar este campo";
                          }
                          if (!valores.lastname) {
                            errores.lastname = "Debes de completar este campo";
                          }
                          if (!valores.email) {
                            errores.email = "Debes de completar este campo";
                          }
                          if (!valores.password) {
                            errores.password = "Debes de completar este campo";
                          }
                          return errores;
                        }}
                        //desde aqui en vio al api
                        onSubmit={(valores) => {
                          let data = {
                            username: valores.username,
                            email: valores.email,
                            password: valores.password,
                            name: valores.name,
                            lastname: valores.lastname,
                            phone: valores.phone,
                          };
                          Swal.fire({
                            title: "Desea editar este usuario?",
                            showDenyButton: true,
                            confirmButtonText: "Si",
                            denyButtonText: `No`,
                          }).then((result) => {
                            if (result.isConfirmed) {
                              updateUser(id, data)
                                .then((result) => {
                                  Swal.fire({
                                    title: "Usuario editado correctamente.",
                                    confirmButtonText: "OK",
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      navigate("/ListUsers");
                                    }
                                  });
                                })
                                .catch((error) => {
                                  Swal.fire("Error editando Usuario");
                                });
                            } else if (result.isDenied) {
                              return;
                            }
                          });
                        }}
                      >
                        {({ errors }) => (
                          <Form className="row g-3">
                            <div className="col-md-6">
                              <label className="form-label">
                                Nombre/s
                              </label>
                              <Field
                                type="text"
                                name="name"
                                id="name"
                                className="form-control"
                                placeholder="Nombre o nombres de la persona"
                              />
                              <ErrorMessage
                                name="name"
                                component={() => (
                                  <div className="text-danger">
                                    {errors.name}
                                  </div>
                                )}
                              />
                            </div><div className="col-md-6">
                              <label className="form-label">
                                Apellido/s
                              </label>
                              <Field
                                type="text"
                                name="lastname"
                                id="lastname"
                                className="form-control"
                                placeholder="Apellido o apellidos de la persona"
                              />
                              <ErrorMessage
                                name="lastname"
                                component={() => (
                                  <div className="text-danger">
                                    {errors.lastname}
                                  </div>
                                )}
                              />
                            </div><div className="col-md-6">
                              <label className="form-label">
                                Numero telefonico
                              </label>
                              <Field
                                type="text"
                                name="phone"
                                id="phone"
                                className="form-control"
                                placeholder="El numero telefonico de la persona"
                              />
                              <ErrorMessage
                                name="phone"
                                component={() => (
                                  <div className="text-danger">
                                    {errors.phone}
                                  </div>
                                )}
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">
                                Nombre Usuario
                              </label>
                              <Field
                                type="text"
                                name="username"
                                id="username"
                                className="form-control"
                                placeholder="El nombre del Usuario"
                              />
                              <ErrorMessage
                                name="username"
                                component={() => (
                                  <div className="text-danger">
                                    {errors.username}
                                  </div>
                                )}
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">Contraseña</label>
                              <Field
                                // type={passwordShown ? "text" : "password"}
                                name="password"
                                id="password"
                                type="text"
                                className="form-control"
                                placeholder="Contraseña para el usuario"
                              />
                              {/* <button onClick={togglePassword}>
                              Show Password
                            </button> */}
                              <ErrorMessage
                                name="password"
                                component={() => (
                                  <div className="text-danger">
                                    {errors.password}
                                  </div>
                                )}
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">
                                Correo Electronico
                              </label>
                              <Field
                                type="email"
                                name="email"
                                id="email"
                                className="form-control"
                                placeholder="Digita el correo electronico del usuario"
                              />
                              <ErrorMessage
                                name="email"
                                component={() => (
                                  <div className="text-danger">
                                    {errors.email}
                                  </div>
                                )}
                              />
                            </div>
                            <div className="col-12">
                              <button
                                type="submit"
                                className="btn btn-primary mx-2"
                              >
                                Editar
                              </button>
                              <Link
                                type="submit"
                                className="btn btn-danger "
                                to="/ListUsers"
                              >
                                Cancelar
                              </Link>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            "Loading......"
          )}
        </div>
      </div>
    </div>
  );
}

export default EditUsers;
