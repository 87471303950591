import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Header from "../Header";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

import {
  getCompanies,
  createUser,
  linkUserToCompany,
} from "msbookingapiclient";

function RegUsers(props) {
  // eslint-disable-next-line no-unused-vars
  let navigate = useNavigate();

  //
  // const [passwordShown, setPasswordShown] = useState(false);

  // const togglePassword = (evt) => {
  //   evt.preventDefault();
  //   setPasswordShown(!passwordShown);

  //
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    getCompanies()
      .then((result) => {
        setCompanies(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Header
        userStatus={props.userStatus}
        title="List Business"
        subtitle="List Business"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body ">
                <div className="card-header">
                  <h3 className="card-title">Registrar Nuevo Usuario</h3>
                </div>
                <div className="card-body">
                  <Formik
                    initialValues={{
                      username: "",
                      name: "",
                      lastname: "",
                      phone: "",
                      password: "",
                      email: "",
                      company: "",
                    }}
                    validate={(valores) => {
                      let errores = {};
                      if (!valores.username) {
                        errores.username = "Debes de completar este campo";
                      }
                      if (!valores.name) {
                        errores.name = "Debes de completar este campo";
                      }
                      if (!valores.phone) {
                        errores.phone = "Debes de completar este campo";
                      }
                      if (!valores.lastname) {
                        errores.lastname = "Debes de completar este campo";
                      }
                      if (!valores.email) {
                        errores.email = "Debes de completar este campo";
                      }
                      if (!valores.password) {
                        errores.password = "Debes de completar este campo";
                      }
                      if (!valores.company) {
                        errores.companies = "Debe seleccionar una Usuario";
                      }
                      return errores;
                    }}
                    //desde aqui envio al api
                    onSubmit={(valores, { resetForm }) => {
                      Swal.fire({
                        title: "Desea crear este Usuario?",
                        showDenyButton: true,
                        confirmButtonText: "Si",
                        denyButtonText: `No`,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          valores.confirmed = true;
                          createUser(valores, "Commerce", false).then(
                            (result) => {
                              linkUserToCompany(result.data.id, valores.company)
                                .then((result2) => {
                                  Swal.fire({
                                    title: "Usuario creado correctamente.",
                                    confirmButtonText: "OK",
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      navigate("/ListUsers");
                                    }
                                  });
                                })
                                .catch((error) => {
                                  Swal.fire("Error creando Usuario");
                                });
                            }
                          );

                          //resetForm();
                        } else if (result.isDenied) {
                          return;
                        }
                      });
                    }}
                  >
                    {({ errors }) => (
                      <Form className="row g-3">
                        <div className="col-md-6">
                          <label className="form-label">Nombre/s</label>
                          <Field
                            type="text"
                            name="name"
                            id="name"
                            className="form-control"
                            placeholder="Nombre o nombres de la persona"
                          />
                          <ErrorMessage
                            name="name"
                            component={() => (
                              <div className="text-danger">
                                {errors.name}
                              </div>
                            )}
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">Apellido/s</label>
                          <Field
                            type="text"
                            name="lastname"
                            id="lastname"
                            className="form-control"
                            placeholder="Apellido o apellidos de la persona"
                          />
                          <ErrorMessage
                            name="lastname"
                            component={() => (
                              <div className="text-danger">
                                {errors.lastname}
                              </div>
                            )}
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">Nombre Usuario</label>
                          <Field
                            type="text"
                            name="username"
                            id="username"
                            className="form-control"
                            placeholder="El nombre del Usuario"
                          />
                          <ErrorMessage
                            name="username"
                            component={() => (
                              <div className="text-danger">
                                {errors.username}
                              </div>
                            )}
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">Numero telefonico</label>
                          <Field
                            type="text"
                            name="phone"
                            id="phone"
                            className="form-control"
                            placeholder="El numero telefonico de la persona"
                          />
                          <ErrorMessage
                            name="phone"
                            component={() => (
                              <div className="text-danger">
                                {errors.phone}
                              </div>
                            )}
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">Contraseña</label>
                          <Field
                            // type={passwordShown ? "text" : "password"}
                            name="password"
                            id="password"
                            className="form-control"
                            placeholder="Contraseña para el usuario"
                          />
                          {/* <button onClick={togglePassword}>
                            Show Password
                          </button> */}
                          <ErrorMessage
                            name="password"
                            component={() => (
                              <div className="text-danger">
                                {errors.password}
                              </div>
                            )}
                          />
                        </div>
                        <div className="col-md-6">
                          <label className="form-label">
                            Correo Electronico
                          </label>
                          <Field
                            type="email"
                            name="email"
                            id="email"
                            className="form-control"
                            placeholder="Digita el correo electronico del usuario"
                          />
                          <ErrorMessage
                            name="email"
                            component={() => (
                              <div className="text-danger">{errors.email}</div>
                            )}
                          />
                        </div>

                        <div className="col-md-12">
                          <label className="form-label">
                            Empresa para el Usuario
                          </label>
                          <Field
                            name="company"
                            as="select"
                            className="form-select"
                          >
                            {companies.map((company) => (
                              <option key={company.id} value={company.id}>
                                {company.attributes.name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="company"
                            component={() => (
                              <div className="text-danger">
                                {errors.company}
                              </div>
                            )}
                          />
                        </div>
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary mx-2"
                          >
                            Registrar
                          </button>
                          <Link
                            type="submit"
                            className="btn btn-danger "
                            to="/ListUsers"
                          >
                            Cancelar
                          </Link>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegUsers;
