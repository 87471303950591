import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Header from "../Header";
import { Link, useParams } from "react-router-dom";
import { getSubscriptionsByCompanyId } from "msbookingapiclient";

function BillingCompany(props) {
  const { id } = useParams();
  const [reservas, setReservas] = useState([]);

  useEffect(() => {
    getSubscriptionsByCompanyId(id)
      .then((result1) => {
        setReservas(result1.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div>
      <Header
        userStatus={props.userStatus}
        title="List Company"
        subtitle="List Company"
      />
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body ">
                <div className="card-header">
                  <h3 className="card-title">Subscriciones</h3>
                </div>
                <div className="m-2">
                  <Link className="btn btn-primary" to={`/NewSubBilling/${id}`}>
                    <span>Asignar subscricion</span>
                  </Link>
                </div>
                <div className="card-body">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Mes</th>
                        <th>Precio</th>
                        <th>Estado</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reservas && reservas.length > 0
                        ? reservas.map((reservate) => (
                          <tr key={reservate.id}>
                            <React.Fragment>
                              <td>{reservate.id} </td>
                              <td>{reservate.attributes.month} </td>
                              <td>{reservate.attributes.amount} </td>
                              <td>{reservate.attributes.status}</td>
                            </React.Fragment>
                          </tr>
                        ))
                        : "Loading......"}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BillingCompany;
