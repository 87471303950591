/* eslint-disable jsx-a11y/role-has-required-aria-props  checkboxSelection*/
import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Header from "../Header";
import { FormControlLabel, IconButton, Switch } from "@material-ui/core";
import { DataGrid } from '@mui/x-data-grid';
import { getCompanies, updateCompany, getCompanyById } from "msbookingapiclient/src/Company";
import { Link } from "react-router-dom"
import Swal from "sweetalert2";
function ListCompany(props) {
  const [companies, setCompaniesArray] = useState([]);

  useEffect(() => {
    getCompanies()
      .then((result) => {
        setCompaniesArray(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const MatEdit = ({ id_row }) => {
    return (
      <FormControlLabel
        control={
          <>
            <IconButton
              color="secondary"
              aria-label="add an alarm"
              title="Facturación"
            >
              <Link to={`/BillingCompany/${id_row}`}><i className="material-icons">&#xe8b0;</i></Link>
            </IconButton>
            <IconButton
              color="secondary"
              aria-label="add an alarm"
              title="Ver detalle"
            >
              <Link to={`/ViewCompany/${id_row}`}><i className="material-icons">&#xE417;</i></Link>
            </IconButton>
            <IconButton
              color="secondary"
              aria-label="add an alarm"
              title="Editar"
            >
              <Link to={`/EditCompany/${id_row}`}><i className="material-icons">&#xE254;</i></Link>
            </IconButton>

          </>
        }
      />

    );
  }
  const EstatusEdit = ({ EstatusCheck }) => {
    const [eventChecked, seteventChecked] = useState(EstatusCheck.attributes.enabled);

    function handleEditStatus(event) {
      const checked = event.target.checked;
      seteventChecked(checked);

      Swal.fire({
        title: "Desea cambiar el estado de esta empresa?",
        showDenyButton: true,
        confirmButtonText: "Si",
        denyButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {

          updateCompany(EstatusCheck.id, { enabled: checked })
            .then((result) => {
              Swal.fire({
                title: "El estado de la empresa se cambio correctamente.",
                confirmButtonText: "OK",
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload(true);
                }
              });
            })
            .catch((error) => {
              console.log(error);
              Swal.fire("Error cambiando el estado de la empresa");
            });
        } else if (result.isDenied) {
          seteventChecked(!checked);
          return;
        }
      });
    }

    return (

      <FormControlLabel
        control={
          <>
            <Switch checked={eventChecked} onChange={handleEditStatus} />
          </>
        }
      />

    );
  }
  const columns = [
    {
      field: 'id',
      headerName: <b>ID</b>,
      width: 30
    },
    {
      field: 'attributesname',
      headerName: <b>Nombre Empresa</b>,
      width: 166,
      valueGetter: (params) => {
        return params.getValue(params.id, "attributes").name;
      }
    },
    {
      field: 'attributesnit',
      headerName: <b>NIT Empresa</b>,
      width: 120,
      valueGetter: (params) => {
        return params.getValue(params.id, "attributes").nit;
      }
    },
    {
      field: 'attributeslegalRepresentant',
      headerName: <b>Representante Legal</b>,
      width: 166,
      valueGetter: (params) => {
        return params.getValue(params.id, "attributes").legalRepresentant;
      }
    },
    {
      field: 'attributeslegalPhone',
      headerName: <b>Teléfono Empresa</b>,
      sortable: false,
      width: 150,
      valueGetter: (params) => {
        return params.getValue(params.id, "attributes").legalPhone;
      }
    },
    {
      field: 'attributeslegalEmail',
      headerName: <b>Correo Electrónico</b>,
      width: 200,
      valueGetter: (params) => {
        return params.getValue(params.id, "attributes").legalEmail;
      }
    }, {
      field: "actions",
      headerName: <b>Acciones</b>,
      sortable: false,
      width: 150,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <div
            style={{ cursor: "pointer" }}
          >
            <MatEdit id_row={params.row.id} />
          </div>
        );
      }
    }, {
      field: "enabled",
      headerName: <b>Estado</b>,
      width: 80,
      renderCell: (params) => {
        return (
          <div
            style={{ cursor: "pointer" }}
          >
            <EstatusEdit EstatusCheck={params.row} />
          </div>
        );
      }
    }
  ];

  const rows = companies;

  return (
    <div>
      <Header
        userStatus={props.userStatus}
        title="List Company"
        subtitle="List Company"
      />
      <div className="container">
        <div className="crud shadow-lg p-3 mb-4 mt-5 bg-body rounded">

          <div className="table-wrapper">
            <Row>
              <div className="col-sm-5 mt-5 mb-4 text-gred">
                <h3>
                  Listado de <b>Empresas</b> Registradas
                </h3>
              </div>

              <div className="col-sm-2 offset-sm-5 mt-5">
                <Link className="btn btn-primary" to="/RegCompany">
                  <span>Nueva Empresa</span>
                </Link>
              </div>
            </Row>
            <Row>
              <div className="table-responsive" style={{ height: 400, width: '100%' }}>

                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}

                />
              </div>
            </Row>
          </div>

        </div>
      </div>
    </div>
  );
}

export default ListCompany;
