import React from "react";
// eslint-disable-next-line no-unused-vars
import logo from "./icon2.png";
import "./Footercss.css";
import { CDBFooter, CDBBtn, CDBIcon, CDBBox } from "cdbreact";

export const Footer = () => {
  const logo = require("./icon2.png");
  return (
    <CDBFooter className="shadow">
      <CDBBox
        display="flex"
        justifyContent="between"
        alignItems="center"
        className="mx-auto py-4 flex-wrap"
        style={{ width: "80%" }}
      >
        <CDBBox display="flex" alignItems="center">
          <img alt="logo" src={logo} width="70px" />
        </CDBBox>
        <CDBBox>
          <small className="ml-2"> &copy; M&S Consultant SAS</small>
        </CDBBox>
        <CDBBox display="flex">
          <CDBBtn flat color="dark" className="p-2">
            <CDBIcon fab icon="facebook-f" />
          </CDBBtn>
          <CDBBtn flat color="dark" className="mx-3 p-2">
            <CDBIcon fab icon="twitter" />
          </CDBBtn>
          <CDBBtn flat color="dark" className="p-2">
            <CDBIcon fab icon="instagram" />
          </CDBBtn>
        </CDBBox>
      </CDBBox>
    </CDBFooter>
  );
};
