import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../Header";
import { getCompanyById, updateCompany } from "msbookingapiclient/src/Company";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function EditCompany(props) {
  let navigate = useNavigate();

  const { id } = useParams();

  const [company, setCompany] = useState(null);

  useEffect(() => {
    getCompanyById(id)
      .then((response) => {
        setCompany(response.data.attributes);
      })
      .catch((error) => {
        console.log("ErrorMessage");
      });
  }, [id]);

  return (
    <div>
      <Header
        userStatus={props.userStatus}
        title="List Company"
        subtitle="List Company"
      />
      <div className="container-fluid">
        <div className="row">
          {company ? (
            <>
              <div className="col">
                <div className="card">
                  <div className="card-body ">
                    <div className="card-header">
                      <h3 className="card-title">Editar Datos De Empresa</h3>
                    </div>
                    <div className="card-body">
                      <Formik
                        initialValues={company}
                        validate={(valores) => {
                          let errores = {};
                          if (!valores.name) {
                            errores.name = "Debes de completar este campo";
                          }
                          if (!valores.nit) {
                            errores.nit = "Debes de completar este campo";
                          }
                          if (!valores.legalRepresentant) {
                            errores.legalRepresentant =
                              "Debes de completar este campo";
                          }
                          if (!valores.legalEmail) {
                            errores.legalEmail =
                              "Debes de completar este campo";
                          }
                          if (!valores.LegalDocumentType) {
                            errores.LegalDocumentType =
                              "Debes de selecionar tu tipo de documento";
                          }
                          if (!valores.legalPhone) {
                            errores.legalPhone =
                              "Debes de completar este campo";
                          }
                          if (!valores.legalDocumentNumber) {
                            errores.legalDocumentNumber =
                              "Debes de completar este campo";
                          }
                          if (!valores.legalAddress) {
                            errores.legalAddress =
                              "Debes de completar este campo";
                          }
                          return errores;
                        }}
                        //desde aqui en vio al api
                        onSubmit={(valores, { resetForm }) => {
                          let data = {
                            name: valores.name,
                            nit: valores.nit,
                            enabled: valores.enabled,
                            legalRepresentant: valores.legalRepresentant,
                            legalAddress: valores.legalAddress,
                            legalEmail: valores.legalEmail,
                            legalPhone: valores.legalPhone,
                            legalDocumentNumber: valores.legalDocumentNumber,
                            LegalDocumentType: valores.LegalDocumentType,
                          };
                          Swal.fire({
                            title: "Desea editar esta empresa?",
                            showDenyButton: true,
                            confirmButtonText: "Si",
                            denyButtonText: `No`,
                          }).then((result) => {
                            if (result.isConfirmed) {
                              updateCompany(id, data)
                                .then((result) => {
                                  Swal.fire({
                                    title: "Empresa editada correctamente.",
                                    confirmButtonText: "OK",
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      navigate("/ListCompany");
                                    }
                                  });
                                })
                                .catch((error) => {
                                  Swal.fire("Error editando empresa");
                                });
                            } else if (result.isDenied) {
                              return;
                            }
                          });
                        }}
                      >
                        {({ errors }) => (
                          <Form className="row g-3">
                            <div className="col-md-6">
                              <label className="form-label">
                                Nombre Empresa
                              </label>
                              <Field
                                type="text"
                                name="name"
                                id="name"
                                className="form-control"
                                placeholder="El nombre de la empresa"
                              />

                              <ErrorMessage
                                name="name"
                                component={() => (
                                  <div className="text-danger">
                                    {errors.name}
                                  </div>
                                )}
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">Nit Empresa</label>
                              <Field
                                type="text"
                                name="nit"
                                id="nit"
                                className="form-control"
                                placeholder="El Nit de la empresa"
                              />
                              <ErrorMessage
                                name="nit"
                                component={() => (
                                  <div className="text-danger">
                                    {errors.nit}
                                  </div>
                                )}
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">
                                Representante Legal
                              </label>
                              <Field
                                type="text"
                                name="legalRepresentant"
                                id="legalRepresentant"
                                className="form-control"
                                placeholder="El nombre del representante"
                              />
                              <ErrorMessage
                                name="legalRepresentant"
                                component={() => (
                                  <div className="text-danger">
                                    {errors.legalRepresentant}
                                  </div>
                                )}
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">
                                Tipo de documento
                              </label>
                              <Field
                                name="LegalDocumentType"
                                as="select"
                                className="form-select"
                              >
                                <option disabled value="">
                                  {company.LegalDocumentType}
                                </option>
                                <option disabled value="">
                                  Seleciona tu tipo de documento
                                </option>
                                <option value="Cédula de ciudadanía">
                                  Cédula de ciudadanía
                                </option>
                                <option value="Cédula de extranjería">
                                  Cédula de extranjería
                                </option>
                                <option value="Pasaporte">Pasaporte</option>
                                <option value="NIT">NIT</option>
                              </Field>
                              <ErrorMessage
                                name="LegalDocumentType"
                                component={() => (
                                  <div className="text-danger">
                                    {errors.LegalDocumentType}
                                  </div>
                                )}
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">
                                Identificacion Representante
                              </label>
                              <Field
                                type="number"
                                name="legalDocumentNumber"
                                id="legalDocumentNumber"
                                className="form-control"
                                placeholder="Numero de identificacion"
                              />
                              <ErrorMessage
                                name="legalDocumentNumber"
                                component={() => (
                                  <div className="text-danger">
                                    {errors.legalDocumentNumber}
                                  </div>
                                )}
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">Dirección</label>
                              <Field
                                type="text"
                                name="legalAddress"
                                id="legalAddress"
                                className="form-control"
                                placeholder="El nombre de la empresa"
                              />
                              <ErrorMessage
                                name="legalAddress"
                                component={() => (
                                  <div className="text-danger">
                                    {errors.legalAddress}
                                  </div>
                                )}
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">
                                Numero Telefonico Empresa
                              </label>
                              <Field
                                type="text"
                                name="legalPhone"
                                id="legalPhone"
                                className="form-control"
                                placeholder="El numero de la empresa"
                              />
                              <ErrorMessage
                                name="legalPhone"
                                component={() => (
                                  <div className="text-danger">
                                    {errors.legalPhone}
                                  </div>
                                )}
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">
                                Correo Electronico Empresa
                              </label>
                              <Field
                                type="email"
                                name="legalEmail"
                                id="legalEmail"
                                className="form-control"
                                placeholder="Digita el correo electronico de la empresa"
                              />
                              <ErrorMessage
                                name="legalEmail"
                                component={() => (
                                  <div className="text-danger">
                                    {errors.legalEmail}
                                  </div>
                                )}
                              />
                            </div>
                            <div className="col-12">
                              <button
                                type="submit"
                                className="btn btn-primary mx-3"
                              >
                                Editar
                              </button>
                              <Link
                                type="submit"
                                className="btn btn-primary "
                                to="/ListCompany"
                              >
                                Cancelar
                              </Link>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            "Loading......"
          )}
        </div>
      </div>
    </div>
  );
}

export default EditCompany;
