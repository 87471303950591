import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Sidebar from "./Sidebar";
import Row from "react-bootstrap/Row";
// eslint-disable-next-line no-unused-vars
import logo from "../icon2.png";

function Header(props) {
  const logo = require("../icon2.png");

  return (
    <div>
      {[false].map((expand) => (
        <Navbar key={expand} bg="light" expand={expand} className="mb-3">
          <Container>
            <Row>
              <Sidebar userStatus={props.userStatus} />
            </Row>
            <img alt="logo" src={logo} width="10%" />
          </Container>
        </Navbar>
      ))}
    </div>
  );
}

export default Header;
