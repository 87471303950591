/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Home from "./Dashboard";
import logo from "../icon2.png";
import { Authenticate } from "msbookingapiclient";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Footer } from "../Footer";
//redux>
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../store/slices/user";
//redux<
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function Login(props) {
  const logo = require("../icon2.png");
  const userState = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");

  const [result, setResult] = useState(null);
  const [credential, setCredential] = useState({
    user: "",
    password: "",
  });
  const DataForm = (ev) => {
    // eslint-disable-next-line default-case
    switch (ev.target.name) {
      case "user":
        credential.user = ev.target.value;
        break;
      case "password":
        credential.password = ev.target.value;
        break;
      default:
        break;
    }
  };
  const navigate = useNavigate();

  const authenticate = async (ev) => {
    ev.preventDefault();
    Authenticate(credential.user, credential.password).then((ev) => {
      if (ev === null) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Correo electrónico o Contraseña incorrecta.",
        });
      } else {
        props.userStatus(true);
        setUser(ev.data);
        navigate("/Dashboard");
      }
    });
  };

  return (
    <>
      <div>
        <Container
          className="shadow"
          style={{
            background: "#E5EAF5",
            marginTop: 100,
            padding: 50,
            borderRadius: "10px",
          }}
        >
          <Row>
            <Col md={6}>
              <img
                src={logo}
                className="img-fluid shadow-4 "
                style={{ marginTop: "8%" }}
                alt=""
              />
              <p>Macrodatum SAS - M&S Consultant SAS</p>
            </Col>
            <Col md={6}>
              <Form id="form-login">
                <Form.Group className="mb-3 text-center" id="formBasicEmail">
                  <Form.Label>Correo electronico</Form.Label>
                  <Form.Control
                    type="email"
                    name="user"
                    id="user"
                    onChange={DataForm}
                    placeholder="Email"
                  />
                </Form.Group>

                <Form.Group className="mb-3 text-center" id="formBasicPassword">
                  <Form.Label>Contraseña</Form.Label>
                  <Form.Control
                    type="password"
                    id="password"
                    name="password"
                    onChange={DataForm}
                    placeholder="Password"
                    autoComplete="on"
                  />
                </Form.Group>

                <Button
                  className="btn btn-primary"
                  style={{ content: "center" }}
                  variant="primary"
                  type="submit"
                  onClick={authenticate}
                >
                  Ingresar
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
      <>
        <Footer />
      </>
    </>
  );
}

export default Login;
