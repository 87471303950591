import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Header from "../Header";
import { FormControlLabel, IconButton } from "@material-ui/core";
import { DataGrid } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import { listUsersCommerce } from "msbookingapiclient";

function ListUsers(props) {
  const [users, setUsersArray] = useState([]);

  useEffect(() => {
    listUsersCommerce()
      .then((result) => {
        setUsersArray(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const MatEdit = ({ index }) => {
    return (
        <FormControlLabel
          control={
            <>
              <IconButton
                color="secondary"
                aria-label="add an alarm"
                title="Ver detalle"
              >
                <Link to={`/ViewUsers/${index}`}><i className="material-icons">&#xE417;</i></Link>
              </IconButton>
              <IconButton
                color="secondary"
                aria-label="add an alarm"
                title="Editar"
              >
                <Link to={`/EditUsers/${index}`}><i className="material-icons">&#xE254;</i></Link>
              </IconButton>
            </>
          }
        />)
  }

  const columns = [
    {
      field: 'id',
      headerName: <b>ID</b>,
      width: 200
    },
    {
      field: 'username',
      headerName: <b>Nombre Usuario</b>,
      width: 400
    },
    {
      field: 'roletype',
      headerName: <b>Rol Usuario</b>,
      width: 300,
      valueGetter: (params) => {
        return params.getValue(params.id, "role").type;
      }
    }, {
      field: "actions",
      headerName: <b>Acciones</b>,
      width: 150,
      renderCell: (params) => {

        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <MatEdit index={params.row.id} />
          </div>
        );
      }
    }
  ];

  const rows = users;


  return (
    <div>
      <Header
        userStatus={props.userStatus}
        title="List Business"
        subtitle="List Business"
      />
      <div className="container">
        <div className="crud shadow-lg p-3 mb-4 mt-5 bg-body rounded">

          <div className="table-wrapper">
            <Row>
              <div className="col-sm-5 mt-5 mb-4 text-gred">
                <h3>
                  Listado de <b>Usuarios</b> Registrados
                </h3>
              </div>

              <div className="col-sm-2 offset-sm-5 mt-5">
                <Link className="btn btn-primary" to="/RegUsers">
                  <span>Nuevo Usuario</span>
                </Link>
              </div>
            </Row>
            <Row>
              <div className="table-responsive" style={{ height: 400, width: '100%' }}>

                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}

                />
              </div>
            </Row>
          </div>

        </div>
      </div>
    </div>
  );
}

export default ListUsers;
