import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Header from "../Header";
import { getUserById } from "msbookingapiclient";

import { Formik, Form, Field } from "formik";

function ViewUsers(props) {
  const { id } = useParams();

  const [Users, setCompany] = useState(null);

  useEffect(() => {
    getUserById(id)
      .then((response) => {
        setCompany(response);
      })
      .catch((error) => {
        console.log("ErrorMessage");
      });
  }, [id]);
  return (
    <div>
      <Header
        userStatus={props.userStatus}
        title="Edit Users"
        subtitle="Edit Users"
      />
      <div className="container-fluid">
        <div className="row">
          {Users ? (
            <>
              <div className="col">
                <div className="card">
                  <div className="card-body ">
                    <div className="card-header">
                      <h3 className="card-title">Detalles de Usuario</h3>
                    </div>
                    <div className="card-body">
                      <Formik initialValues={Users}>
                        {({ a }) => (
                          <Form className="row g-3">
                            <div className="col-md-6">
                              <label className="form-label">
                                Nombres
                              </label>
                              <Field
                                type="text"
                                name="name"
                                id="name"
                                className="form-control"
                                disabled
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">
                                Apellidos
                              </label>
                              <Field
                                type="text"
                                name="lastname"
                                id="lastname"
                                className="form-control"
                                disabled
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">
                                Numero telefonico
                              </label>
                              <Field
                                type="text"
                                name="phone"
                                id="phone"
                                className="form-control"
                                disabled
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">
                                Nombre Usuario
                              </label>
                              <Field
                                type="text"
                                name="username"
                                id="username"
                                className="form-control"
                                placeholder="El nombre del Usuario"
                                disabled
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">
                                Correo Electronico
                              </label>
                              <Field
                                type="email"
                                name="email"
                                id="email"
                                className="form-control"
                                placeholder="Digita el correo electronico del usuario"
                                disabled
                              />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">Rol</label>
                              <Field
                                type="text"
                                name="role.name"
                                id="role.name"
                                className="form-control"
                                placeholder="Digita el correo electronico del usuario"
                                disabled
                              />
                            </div>
                            <div className="col-12">
                              <Link
                                type="submit"
                                className="btn btn-primary "
                                to="/ListUsers"
                              >
                                Regresar
                              </Link>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            "Loading......"
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewUsers;
